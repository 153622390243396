import { FloatButton} from 'antd';
import {useEffect, useState} from 'react';
import './ContentIndex.css';
const logoImg = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/logo2.png';
const jieshao = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/xq/培训介绍图片.jpg';
const yemei = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/xq/培训详情页眉.jpg';
const xia = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/xq/logo至于每一个介绍最下方.png';
const logo22 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/logo22.png';
const weixing = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/weixing.jpg';
const App = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    function createMarkup() {
        return {
            __html: `<div class="nyrcon">
	<div class="nyrt">
		<span>
			实验室人员培训
		</span>
	</div>
	<div class="nxm_xq">
		<div class="nxcon">
			<p style="text-align: left;">
				<span style="font-size: 16px; font-family: arial, helvetica, sans-serif; color: rgb(0, 0, 0);">
					<strong>
						<span style="font-family: arial, helvetica, sans-serif; font-size: 16px; color: rgb(54, 96, 146);">
							实验室人员培训咨询服务：
						</span>
					</strong>
				</span>
			</p>
			<p style="text-align: center;">
				<img width="50%" style="margin: auto;" src="`+jieshao+`" /></p>
			<p>
				<span style="color: rgb(0, 0, 0);">
					<strong>
						<span style="background: rgb(255, 255, 255); color: rgb(89, 89, 89); font-family: arial, helvetica, sans-serif; font-size: 16px;"></span>
					</strong>
				</span>
			</p>
			<p style="text-align: left;">
				<span style="font-size: 16px; font-family: arial, helvetica, sans-serif; color: rgb(0, 0, 0);">
					<strong>
						<span style="font-family: arial, helvetica, sans-serif; font-size: 16px; color: rgb(54, 96, 146);">
							实验室人员培训的定义：
						</span>
					</strong>
				</span>
			</p>
			<p>
				<span style="color: rgb(0, 0, 0);"></span>
			</p>
			<p>
				<span style="color: rgb(0, 0, 0);">
					培训相关检测知识,适用于法律法规、实验室安全、管理体系文件、仪器设备原理、标准解读等内容的培训。 通过培训，使实验室的人员了解各自的职责，为您提供专业、全面的实验室内审员培训服务，帮助实验室成员掌握如何有效实施新版体系，使实验室达到正常运行和自我改进的目的。
				</span>
			</p>
			<p style="text-align: left;">
				<span style="font-size: 16px; font-family: arial, helvetica, sans-serif; color: rgb(0, 0, 0);">
					<strong>
						<span style="font-family: arial, helvetica, sans-serif; font-size: 16px; color: rgb(54, 96, 146);">
							实验室人员培训内容：
						</span>
					</strong>
				</span>
			</p>
			<p>
				<span style="color: rgb(0, 0, 0);">
					（1）1、职业道德培训<br/>
					从业人员掌握职业道德基本知识和职业守则。。
				</span>
			</p>
			<p>
				<span style="color: rgb(0, 0, 0);">
					2、岗位职责和法律法规<br/>
					明确其从业人员的职责，上岗前进行岗位职责的培训，确保不同岗位的人员掌握各自的职责和工作内容。
				</span>
			</p>
			<p>
				<span style="color: rgb(0, 0, 0);">
					3、CMA和CNAS认可文件培训<br/>
包括但不限于以下文件的培训:<br/>
--RB/T 214检验检测机构资质认定能力评价检验检测机构通用要求;<br/>
--CNAS-CL01检测和校准实验室能力认可准则;<br/>
--CNAS-CL01-G001检测和校准实验室能力认可准则应用要求;<br/>
--CNAS-CL01-A001检测和校准实验室能力认可准则在微生物检测领域的应用说明;<br/>
--CNAS-CL01-A002检测和校准实验室能力认可准则在化学检测领域的应用说明 等等...
				</span>
			</p>
			<p>
				<span style="color: rgb(0, 0, 0);">
					4、检验检测基础知识培训<br/>
对于涉及检验检测技术的岗位，授权签字人、技术负责人、采样员、检测员、报告编制员、报告审核员、质量监督员，视其教育背景、工作背景、工作内容进行计量基础知识，有效数字的保留和数值修约基础知识;原始记录的填写和检验报告的出具基础知识;检测方法验证基础知识;测量不确定度评定基础知识等相关技术培训。
				</span>
			</p>
			<p>
				<span style="color: rgb(0, 0, 0);">
					5、仪器设备使用维护培训
• 对于涉及仪器使用的岗位,授权签字人、技术负责人、采样员、检测员、质量监督员,视其工作背景、工作内容进行相关仪器设备原理及使用维护的培训。<br/>
• 对于涉及仪器使用的岗位,授权签字人、技术负责人、采样员、检测员、质量监督员，视其工作背景、工作内容进行相关仪器设备原理及使用维护的培训。<br/>
• 现场采样和检测人 员应掌握相关基础采样仪器的使用操作和日常维护。<br/>
• 实验室检测人员应掌握相关基础检测仪器的使用操作和日常维护。<br/>
• 其他岗位人员应掌握仪器设备的基本原理、适用范围和维护要求
				</span>
			</p>
			<p>
				<span style="color: rgb(0, 0, 0);">
					6、检验检测应用技术培训<br/>
• 对于涉及检验检测的岗位,如授权签字人、技术负责人、检测员、质量监督员,视其教育背景、工作经历、工作内容进行相关检验检测应用技术的培训。
				</span>
			</p>
			<p>
				<span style="color: rgb(0, 0, 0);">
					7、实验室基础设施的配置及维护<br/>
对技术负责人、检测员、质量监督员、设施管理员，应进行环境监测实验室基础设施的配置及维护培训
				</span>
			</p>
			<p>
				<span style="font-size: 16px; font-family: arial, helvetica, sans-serif; color: rgb(0, 0, 0);">
					<strong>
						<span style="font-size: 16px; color: rgb(54, 96, 146); font-family: 宋体;"></span>
					</strong>
				</span>
			</p>
			<p>
				<span style="font-family: arial, helvetica, sans-serif; font-size: 16px; color: rgb(0, 0, 0);"></span>
			</p>
		
		</div>
	</div>
</div>`
        };
    }

    return (

        <>
            <FloatButton.BackTop visibilityHeight={0}  style={{zIndex:9999999999999999}}/>
            <div className="fixed is_PC"></div>
            <div className="all_width is_PC">

                <div className="warp">
                    <div className="header">
                         <div className="logo"><a href="#/ContentIndex" style={{float:"left",marginRight:15}}><img src={logoImg} height={108}/></a></div>
                        <div className="new_nav">
                            <ul>
                                <li><a href="#/ContentIndex">首页</a></li>
                                <li><a  href="#/cnas">CNAS认可</a></li>
                                <li><a  href="#/cma">CMA认定</a></li>
                                <li><a  href="#/fanghuo">防火检测</a></li>
                                <li><a  href="#/haishui">海水腐蚀</a></li>
                                <li><a   href="#/about">关于我们</a></li>
                            </ul>
                        </div>
                        <div className="vub_B" style={{position:"fixed",top:"30px"}}>联系电话： 18957441030</div>
                    </div>
                </div>
            </div>
            <img src={yemei}/>
            <div dangerouslySetInnerHTML={createMarkup()}/>
            <img src={xia} width={'100%'} style={{zIndex:999,position:"relative",marginTop:80,display:"block"}}/>

             <div className="all_fnav is_PC" style={{zIndex:999,position:"relative"}}>
                 <div style={{background:'#fff',height:80}}></div>
                <div className="warp">
                    <div className={'b'}>
                        <img src={logo22} height={108} style={{float:"left"}}/>
                        <div style={{float:"right",lineHeight:'108px',fontSize:'26px',paddingRight:40}}>致力于我国实验室建设、 认证、 扩项、 监督评审、 一站式解决方案</div>
                    </div>
                    <div className="Fanya_footer_nav" style={{color:'#FFF'}}>
                        <ul>
                            <li>
                                
                                <div className="Fanya_footer_nav_TT">CMA资质认定</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>检验检测机构CMA资质认定</dt>
                                        <dt>机动车检验机构CMA资质认定</dt>
                                        <dt>司法鉴定机构CMA资质认定</dt>
                                        <dt>刑事技术机构CMA资质认定</dt>
                                    </dl>
                                </div>
                            </li>
                            <li><div className="Fanya_footer_nav_TT">CNAS认证认可</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>ISO17025检测/校准实验室CNAS认可</dt>
                                        <dt>ISO17020检验机构CNAS认可</dt>
                                        <dt>ISO15189医学实验室CNAS认可</dt>
                                        <dt>DILAC国防实验室认可</dt>
                                    </dl>
                                </div>
                            </li>
                            <li style={{marginRight:15}}>
                                <div className="Fanya_footer_nav_TT">联系我们</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>电话：0574-87636695</dt>
                                        <dt>认证热线：18957441030（胡老师）</dt>
                                        <dt>地址：宁波市高新区检验检测园冬青路258号A幢</dt>
                                        <dt></dt>
                                    </dl>
                                </div>
                            </li>
                            <li>
                                <div className="Fanya_footer_sub" style={{position:"relative"}}>
                                    <img src={weixing} width={160} />
                                    <div style={{background:'red',borderRadius:16,lineHeight:'32px',textAlign:"center",width:160,marginTop:10}}>扫码咨询</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="all_Fanya_copyright">
                <div className="warp">
                    <div className="Fanya_copyright">版权所有 ◎ 浙江安鉴检测认证服务有限公司 <a
                        href="http://beian.miit.gov.cn/" target="_blank">浙ICP备2024103308号-1</a></div>
                </div>
            </div>
        </>
    );
};
export default App;
