import {Layout, Button, Carousel, Tabs,FloatButton} from 'antd';
import {MessageOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import {useEffect, useState} from 'react';
import Script from 'react-load-script';
import $ from 'jquery';
import axios from 'axios';
import {Navigation, Pagination, Scrollbar, A11y, Autoplay} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import {Link} from 'react-router-dom';    // 路由插件
import './ContentIndex.css';
const logoImg = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/logo2.png';
const T2png = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/T2.png';
const zs1 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/zs/大众1.jpg';
const zs11 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/zs/大众1反面.jpg';
const zs2 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/zs/新海2.jpg';
const zs22 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/zs/新海22.jpg';
const zs3 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/zs/新海5.jpg';
const zs33 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/zs/新海55.jpg';
const zs4 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/zs/杰林3.jpg';
const zs44 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/zs/杰林33.jpg';
const zs5 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/zs/浦海6.png';
const zs55 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/zs/浦海66.jpg';
const zs6 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/zs/金羊2.jpg';
const zs66 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/zs/金羊22.jpg';
const cganli = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/kehu LOGO.png';
const beijing = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/背景.jpg';
const jc1 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/jc/力学.jpg';
const jc3 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/jc/成分分析.jpg';
const jc4 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/jc/无损.jpg';
const jc5 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/jc/焊接.jpg';
const jc7 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/jc/腐蚀.jpg';
const jc8 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/jc/金相.jpg';
const hsfs = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/海水腐蚀.jpg';
const fhjc = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/防火检测.jpg';
const img2 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/2.png';
const img3 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/3.jpg';
const img4 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/4.png';
const gs = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/公司图片(1).jpg';
const weixing = "https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/weixing.jpg";
const logo22 = "https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/logo22.png";
// const chenfen from "./jianche/chenfen";

const shouye = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/首页.jpg';
const {Header, Footer, Sider, Content} = Layout;
const App = () => {
    useEffect(() => {
        // 配置swiper播放 配置项都写这里面
        // console.log(1232112312)
        // const mySwiper = new Swiper('.swiper', {
        //     autoplay: true,
        //     loop: true
        // })
        window.scrollTo(0, 0);
    }, [])
    const CoreServices = (obj) => {
        $('.num li:nth-child(' + obj + ')').addClass('on').siblings().removeClass('on')
        $('.infoList').animate({
            left: -346 * (obj - 1)
        }, 500)

    }
    let zs=[];
    for (let i=0;i<6;i++){
        let src1=zs1
        let src2=zs11
        if (i===0){
            src1=zs1
            src2=zs11
        }
        if (i===1){
            src1=zs2
            src2=zs22
        }
        if (i===2){
            src1=zs3
            src2=zs33
        }
        if (i===3){
            src1=zs4
            src2=zs44
        }
        if (i===4){
            src1=zs5
            src2=zs55
        }
        if (i===5){
            src1=zs6
            src2=zs66
        }
        zs.push(<SwiperSlide>
            <div className="flip-box">
                <div className="flip-inner">
                    <div className="flip-front">
                        <div className="fanya_zsx">
                            <div className="fanya_zsx_A"><img
                                src={src1} width={266} height={362}/></div>
                            {/*<div className="fanya_zsx_B">检验检测实验室CNAS认可</div>*/}
                        </div>
                    </div>
                    <div className="flip-back" style={{padding:6}}>
                        <div className="fanya_zsx_A"><img src={src2} width={266} height={362}/></div>
                    </div>
                </div>
            </div>
        </SwiperSlide>)
    }
    return (

        <>
            <FloatButton.BackTop visibilityHeight={0}  style={{zIndex:9999999999999999}}/>
            {/*<div className="warp is_PC">*/}
            {/*    <div className="vub">*/}
            {/*        /!*<div className="vub_A">*!/*/}
            {/*        /!*    <ul>*!/*/}
            {/*        /!*        <li><a target="_blank">关于我们</a></li>*!/*/}
            {/*        /!*        <li><a target="_blank">联系我们</a></li>*!/*/}
            {/*        /!*    </ul>*!/*/}
            {/*        /!*</div>*!/*/}
            {/*        <div className="vub_B">联系电话： 18957441030</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="fixed is_PC"></div>
            <div className="all_width is_PC">
                <div className="warp">
                    <div className="header">
                        <div className="logo"><a href="#/ContentIndex" style={{float:"left",marginRight:15}}><img src={logoImg} height={108}/></a></div>
                        <div className="new_nav">
                            <ul>
                                <li><a href="#/ContentIndex">首页</a></li>
                                <li><a  href="#/cnas">CNAS认可</a></li>
                                <li><a  href="#/cma">CMA认定</a></li>
                                <li><a  href="#/fanghuo">防火检测</a></li>
                                <li><a  href="#/haishui">海水腐蚀</a></li>
                                <li><a   href="#/about">关于我们</a></li>
                            </ul>
                        </div>
                        <div className="vub_B" style={{position:"fixed",top:"30px"}}>联系电话： 18957441030</div>
                    </div>
                </div>
            </div>
            <Carousel effect="fade" autoplay>
                <div style={{position:'relative'}}>
                    {/*<img src={bannerBG}/>*/}
                    <img src={shouye} width={'100%'}/>
                    <div style={{position:"absolute",top:'15%',left:'50px',fontSize:50,color:'#fff',fontWeight:"bold",width:1000,textAlign:"center"}}>
                        <span>专业从事实验室CNAS/CMA认证</span><br />
                        <span>上门服务 辅导客户通过率100%</span><br />
                        <span style={{fontWeight:100,fontSize:31}}>致力于检测和校准实验室建设、认证、扩项、监督评审、一站式解决方案</span>
                    </div>
                </div>
                {/*<div style={{position:'relative'}}>*/}
                {/*    <img src={bannerBG}/>*/}
                {/*    <img src={banner2} width={800} height={450} style={{position:'absolute',top:125,right:60}}/>*/}
                {/*</div>*/}
                {/*<div style={{position:'relative'}}>*/}
                {/*    <img src={bannerBG}/>*/}
                {/*    <img src={banner3} width={800} height={450} style={{position:'absolute',top:125,right:60}}/>*/}
                {/*</div>*/}
            </Carousel>

            <div className="all_Fanya_three">
                <div className="warp">
                    <br />
                    <br />
                    <div className="Fanya_three_TT">认证服务</div>
                    <br />
                    <div
                        className="Fanya_three_Desc">我们利用多年积累的丰富经验帮助客户从CNAS/CMA认证认可资质申请、实验室规划、设计施工、实验室技术培训等多方面来实现实验室一站式解决方案。
                    </div>
                </div>
                <div className="Fanya_csr-left is_PC">
                    <div className="bd">
                        <div className="tempWrap">
                            <ul className="infoList">
                                <li>
                                    <a target="_blank">
                                        <div className="Fanya_csr_five">
                                            <div className="Fanya_csr_fiveA"><img
                                                src={T2png} width={320} height={136}/></div>
                                            <div className="Fanya_csr_fiveB">CMA检验检测资质认定</div>
                                            <div
                                                className="Fanya_csr_fiveD">适用于出具权威检测报告的第三方检测机构
                                            </div>
                                            <a href="#/cma"><div className="Fanya_csr_fiveE">了解详情</div></a>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank">
                                        <div className="Fanya_csr_five">
                                            <div className="Fanya_csr_fiveA"><img
                                                src={img2} width={320} height={136}/></div>
                                            <div className="Fanya_csr_fiveB">CNAS实验室认可</div>
                                            <div
                                                className="Fanya_csr_fiveD">适用于第三方检测公司或者第一方工厂内部实验室
                                            </div>
                                            <Link to={'/cnas'}  key={'cnas'}><div className="Fanya_csr_fiveE">了解详情</div></Link>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank">
                                        <div className="Fanya_csr_five">
                                            <div className="Fanya_csr_fiveA"><img
                                                src={img3} width={320} height={136}/></div>
                                            <div className="Fanya_csr_fiveB">实验室培训</div>
                                            <div
                                                className="Fanya_csr_fiveD">CMA和CNAS认可文件、内审员以及相关检验检测的应用技术培训
                                            </div>
                                            <Link to={'/peixun'}  key={'peixun'}><div className="Fanya_csr_fiveE">了解详情</div></Link>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank">
                                        <div className="Fanya_csr_five">
                                            <div className="Fanya_csr_fiveA"><img
                                                src={img4} width={320} height={136}/></div>
                                            <div className="Fanya_csr_fiveB">实验室复评、扩项咨询业务</div>
                                            <div
                                                className="Fanya_csr_fiveD">提供实验室每两年一次的复评审加扩项的咨询服务
                                            </div>
                                            <Link to={'/kuoxiang'}  key={'kuoxiang'}><div className="Fanya_csr_fiveE">了解详情</div></Link>
                                        </div>
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>
                    {/*<div className="hd">*/}
                    {/*    <div className="hd_xi">*/}
                    {/*        <a className="prev"></a>*/}
                    {/*        <ul className="num">*/}
                    {/*            <li onClick={event => CoreServices(1)} className="on">1</li>*/}
                    {/*            <li onClick={event => CoreServices(2)} className="">2</li>*/}
                    {/*            <li onClick={event => CoreServices(3)} className="">3</li>*/}
                    {/*            <li onClick={event => CoreServices(4)} className="">4</li>*/}
                    {/*            <li onClick={event => CoreServices(5)} className="">5</li>*/}
                    {/*            <li onClick={event => CoreServices(6)} className="">6</li>*/}
                    {/*        </ul>*/}
                    {/*        <a className="next"></a>*/}
                    {/*        <span className="pageState"><span>1</span>/6</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>

            {/*<div className="all_fanya_cc">*/}
            {/*    <div>*/}
            {/*        <div className="fanya_cc">*/}
            {/*            <ul>*/}
            {/*                <li>*/}
            {/*                    <div className="fanya_cc_li">*/}
            {/*                        /!*<div className="fanya_cc_lia"><img src="http://fanyalab.com/images/cnas_ico.png"/>*!/*/}
            {/*                        /!*</div>*!/*/}
            {/*                        <div className="fanya_cc_lib">海水腐蚀测试（非模拟）</div>*/}
            {/*                        <div*/}
            {/*                            className="fanya_cc_lic">本公司是全国唯一一家可以真实海水腐蚀试验的机构，海水腐蚀试验用于评估材料在海水环境下的耐蚀性能。包括金属材料涂层材料、防腐材料等。在海洋工程、船舶制造、海洋石油开采等领域中，材料的腐蚀性能对设备和结构的安全运行至关重要。因此海水腐蚀试验对于材料的研发和选用具有重要意义。*/}
            {/*                        </div>*/}
            {/*                        <div className="fanya_cc_lid"><a target="_blank">查看详情</a></div>*/}
            {/*                    </div>*/}
            {/*                </li>*/}
            {/*                <li>*/}
            {/*                    <div className="fanya_cc_li">*/}
            {/*                        /!*<div className="fanya_cc_lia"><img src="http://fanyalab.com/images/cma_ico.png"/>*!/*/}
            {/*                        /!*</div>*!/*/}
            {/*                        <div className="l100 fanya_cc_lib">防火测试</div>*/}
            {/*                        <div*/}
            {/*                            className="l100 fanya_cc_lic">防火检测是使用现代高科技仪器设备，如采用红外测温仪测温、红外热像仪拍热谱图以及采用超声探测仪测量异常高温、火花放电等现象及使用常规电工仪器、仪表如，电压表、电流表、验电器、接地电阻测试仪、真有效值电流表等，对运行中的电气设施的各项运行参数进行测量。</div>*/}
            {/*                        <div className="l100 fanya_cc_lid"><a target="_blank">查看详情</a></div>*/}
            {/*                    </div>*/}
            {/*                </li>*/}
            {/*            </ul>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <br />
            <br />
            <br />
            <div style={{position:"relative"}}>
                <img src={beijing} style={{position:"absolute",width:'100%',height:'100%',zIndex:-1}} />
            <div className="all_fanya_zs" style={{padding:0}}>
                <div className="warp"><br />
                    <div className="Fanya_three_TT">客户案例</div>
                    <br />
                    <br />
                    {/*<div className="Fanya_three_Desc">真实案例，请勿盗用，违者必究</div>*/}
                    <div className="fanya_zs is_PC">
                        <div className="ohbox">
                            <div className="tempWrap">
                                <Swiper
                                    spaceBetween={0}
                                    slidesPerView={4}
                                    onSlideChange={() => console.log('slide change')}
                                    onSwiper={(swiper) => console.log(swiper)}
                                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                                    autoplay={
                                        {
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }
                                    }
                                    navigation={{
                                        nextEl: ".pageBtn .next",
                                        prevEl: ".pageBtn .prev",
                                    }}
                                    // pagination={{ clickable: true }}
                                    // scrollbar={{ draggable: true }}
                                >
                                    {zs}
                                </Swiper>
                            </div>
                        </div>
                        <div className="pageBtn">
                            <span className="prev">&lt;</span>
                            <span className="next">&gt;</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <img src={cganli} width={'100%'} />
            </div>
            </div>
            {/*<div className="all_fanya_cc">*/}
            {/*    <div>*/}
            {/*        <div className="fanya_cc">*/}
            {/*            <ul>*/}
            {/*                <li>*/}
            {/*                    <div className="fanya_cc_li">*/}
            {/*                        /!*<div className="fanya_cc_lia"><img src="http://fanyalab.com/images/cnas_ico.png"/>*!/*/}
            {/*                        /!*</div>*!/*/}
            {/*                        <div className="fanya_cc_lib">海水腐蚀测试（非模拟）</div>*/}
            {/*                        <div*/}
            {/*                            className="fanya_cc_lic">本公司是全国唯一一家可以真实海水腐蚀试验的机构，海水腐蚀试验用于评估材料在海水环境下的耐蚀性能。包括金属材料涂层材料、防腐材料等。在海洋工程、船舶制造、海洋石油开采等领域中，材料的腐蚀性能对设备和结构的安全运行至关重要。因此海水腐蚀试验对于材料的研发和选用具有重要意义。*/}
            {/*                        </div>*/}
            {/*                        <div className="fanya_cc_lid"><a target="_blank">查看详情</a></div>*/}
            {/*                    </div>*/}
            {/*                </li>*/}
            {/*                <li>*/}
            {/*                    <div className="fanya_cc_li">*/}
            {/*                        /!*<div className="fanya_cc_lia"><img src="http://fanyalab.com/images/cma_ico.png"/>*!/*/}
            {/*                        /!*</div>*!/*/}
            {/*                        <div className="l100 fanya_cc_lib">防火测试</div>*/}
            {/*                        <div*/}
            {/*                            className="l100 fanya_cc_lic">防火检测是使用现代高科技仪器设备，如采用红外测温仪测温、红外热像仪拍热谱图以及采用超声探测仪测量异常高温、火花放电等现象及使用常规电工仪器、仪表如，电压表、电流表、验电器、接地电阻测试仪、真有效值电流表等，对运行中的电气设施的各项运行参数进行测量。</div>*/}
            {/*                        <div className="l100 fanya_cc_lid"><a target="_blank">查看详情</a></div>*/}
            {/*                    </div>*/}
            {/*                </li>*/}
            {/*            </ul>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="all_fanya_zs" style={{padding:0}}>
                <div className="warp" >
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="Fanya_three_TT">检测项目</div>
                    <br />
                    <br />
                </div>
                <div className="n_list1 list_921">
                    <div className="item" style={{width:'50%',marginRight:0,position:"relative"}}>
                        <a href="#/haishui">
                            <div className={'img'}><img src={hsfs} width={'100%'}/></div>
                            <div className={'hover'}>
                                海水腐蚀(非模拟)<br /><br />
                                本公司是全国唯一可以真实海水腐蚀试验的机构，海水腐蚀试验用于评估材料在海水环境下的耐蚀性能。包括属材料涂层材料、防腐材料等。
                                在海洋工程、船舶制造、海洋石油开采等领域中，材料的腐蚀性能对设备和结构的安全运行至关重要。
                            </div>
                            <div className="title" style={{fontSize:26}}>海水腐蚀</div>
                        </a>
                    </div>
                    <div className="item" style={{width:'50%',marginRight:0,position:"relative"}}>
                        <a href="#/fanghuo">
                            <div className={'img'}><img src={fhjc} width={'100%'}/></div>
                            <div className={'hover'}>
                                航空管路耐火试验<br /><br />
                                航空管路耐火试验的目的是检测航空管路在受到火焰作用时的耐火性能，以评估其在火灾环境下的安全性和可靠性，验证组件满足CCAR23.1183、CCAR25.1183、CCAR27.1183、CCAR29.1183以及组件专用规范规定的耐火要求。
                            </div>
                            <div className="title" style={{fontSize:26}}>防火检测</div>

                        </a>
                    </div>
                </div>
                <br />
                <br />
                <div className="warp" >
                    {/*<div*/}
                    {/*    className="Fanya_three_Desc">环境检测、塑料领域、油品检测、化妆品领域、未知物分析、化学药领域、食品金属*/}
                    {/*</div>*/}
                    {/*<div className="fanya_zs is_PC">*/}
                    {/*    <div className="fanya_zs is_PC">*/}
                    {/*        <div className="ohbox">*/}
                    {/*            <div className="bm-main">*/}
                    {/*                <ul className="bm-main-ul">*/}
                    {/*                    <li className="bm-main-li flex_between">*/}
                    {/*                        <div className="bm-main-left flex_column">*/}
                    {/*                            <a className="link_a flex_column">*/}
                    {/*                                <h5>力学内</h5>*/}
                    {/*                                <img*/}
                    {/*                                    src={jc2}*/}
                    {/*                                    width={240}*/}
                    {/*                                    height={166}*/}
                    {/*                                    alt=""/>*/}
                    {/*                                <p className="two_row_ellipsis">新型墙体材料...</p>*/}
                    {/*                                <span>查看详情 &gt;</span></a></div>*/}
                    {/*                        <div className="bm-main-right">*/}
                    {/*                            <ul className="flex_between">*/}
                    {/*                                <li className="animate_hover"><a className="">*/}
                    {/*                                    <h5>力学 </h5> <p className="one_row_ellipsis">塌落度结时间...</p>*/}
                    {/*                                    <img*/}
                    {/*                                        src={jc1}*/}
                    {/*                                        alt=""/></a></li>*/}
                    {/*                                <li className="animate_hover"><a className="">*/}
                    {/*                                    <h5>成分分析</h5> <p className="one_row_ellipsis">塌落度结时间...</p>*/}
                    {/*                                    <img*/}
                    {/*                                        src={jc3}*/}
                    {/*                                        alt=""/></a></li>*/}
                    {/*                                <li className="animate_hover"><a className="">*/}
                    {/*                                    <h5>无损 </h5> <p className="one_row_ellipsis">塌落度结时间...</p>*/}
                    {/*                                    <img*/}
                    {/*                                        src={jc4}*/}
                    {/*                                        alt=""/></a></li>*/}
                    {/*                                <li className="animate_hover"><a className="">*/}
                    {/*                                    <h5>焊接 </h5> <p className="one_row_ellipsis">塌落度结时间...</p>*/}
                    {/*                                    <img*/}
                    {/*                                        src={jc5}*/}
                    {/*                                        alt=""/></a></li>*/}
                    {/*                                <li className="animate_hover"><a className="">*/}
                    {/*                                    <h5>海水腐蚀 </h5> <p className="one_row_ellipsis">塌落度结时间...</p>*/}
                    {/*                                    <img*/}
                    {/*                                        src={jc6}*/}
                    {/*                                        alt=""/></a></li>*/}
                    {/*                                <li className="animate_hover"><a className="">*/}
                    {/*                                    <h5>腐蚀 </h5> <p className="one_row_ellipsis">塌落度结时间...</p>*/}
                    {/*                                    <img*/}
                    {/*                                        src={jc7}*/}
                    {/*                                        alt=""/></a></li>*/}
                    {/*                                <li className="animate_hover"><a className="">*/}
                    {/*                                    <h5>金相 </h5> <p className="one_row_ellipsis">塌落度结时间...</p>*/}
                    {/*                                    <img*/}
                    {/*                                        src={jc8}*/}
                    {/*                                        alt=""/></a></li>*/}
                    {/*                                <li className="animate_hover"><a className="">*/}
                    {/*                                    <h5>防火试验</h5> <p className="one_row_ellipsis">塌落度结时间...</p>*/}
                    {/*                                    <img*/}
                    {/*                                        src={jc9}*/}
                    {/*                                        alt=""/></a></li>*/}
                    {/*                            </ul>*/}
                    {/*                        </div>*/}
                    {/*                    </li>*/}
                    {/*                </ul>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div>
                        <div className="n_list1 list_921">
                            <div className="item">
                                <a  href="#/lixue">
                                    <div className={'img'}><img src={jc1} width={545} height={280} /></div>
                                    <div className="title">金属材料力学性能测试</div>
                                </a>
                            </div>
                            {/*<div className="item">*/}
                            {/*    <a href="/solution/detail/129">*/}
                            {/*        <div className={'img'}><img src={jc2} width={545} height={280} /></div>*/}
                            {/*        <div className="title">金属材料力学内性能测试</div>*/}
                            {/*    </a>*/}
                            {/*</div>*/}
                            <div className="item">
                                <a href="#/chenfen">
                                    <div className={'img'}><img src={jc3} width={545} height={280} /></div>
                                    <div className="title">金属材料成分分析</div>
                                </a>
                            </div>
                            <div className="item">
                                <a href="#/wushun">
                                    <div className={'img'}><img src={jc4} width={545} height={280} /></div>
                                    <div className="title">无损探伤</div>
                                </a>
                            </div>
                            <div className="item">
                                <a  href="#/hanjie">
                                    <div className={'img'}><img src={jc5} width={545} height={280} /></div>
                                    <div className="title">金属材料焊接工艺评定</div>
                                </a>
                            </div>
                            {/*<div className="item">*/}
                            {/*    <a href="/solution/detail/129">*/}
                            {/*        <div className={'img'}><img src={jc6} width={545} height={280} /></div>*/}
                            {/*        <div className="title">海水腐蚀</div>*/}
                            {/*    </a>*/}
                            {/*</div>*/}
                            <div className="item">
                                <a  href="#/fushi">
                                    <div className={'img'}><img src={jc7} width={545} height={280} /></div>
                                    <div className="title">金属材料腐蚀测试</div>
                                </a>
                            </div>
                            <div className="item">
                                <a href="#/jingxiang">
                                    <div className={'img'}><img src={jc8} width={545} height={280} /></div>
                                    <div className="title">金属材料金相分析</div>
                                </a>
                            </div>
                        {/*    <div className="item">*/}
                        {/*    <a href="/solution/detail/129">*/}
                        {/*        <div className={'img'}><img src={jc9} width={545} height={280} /></div>*/}
                        {/*        <div className="title">防火试验</div>*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
            <div className="Kanya_banner_jianjie">
                <br />
                <br />
                <br />
                <div className="Fanya_three_TT">安鉴认证简介</div>
                <br />
                <br />
                <div className="banner_jianjie">
                    {/*<div className="banner_jianjie_A">安鉴认证简介</div>*/}
                    <div className="banner_jianjie_B" style={{top: 0,width:600}}>
                        浙江安鉴检测认证服务有限公司是专门致力于我国实验室认可的咨询事业。目前公司开展的主要咨询项目有CNAS资质认证、CMA认可咨询、检测/校准实验室认可、实验室资质认定、检验机构认可、医学实验室认可、司法鉴定、国防实验室认可、能力验证/测量审核、API体系认可及专业技能培训等。<br/>
                        公司2015年建立了以宁波高新区总部、在江苏无锡和南京建有分公司，迄今已为超过 500 家大中型企业提供专业技术咨询服务。服务客户涉及建筑、环境、食品、药品、冶金、电子、电器、汽车、水处理、血液、疾控、医学检验、石油、化工、钢铁、塑料、机车零部件、纺织、校准等行业。，为客户提供各位周到、便捷的资讯服务。<br/>

                        <p style={{fontWeight:"bold",color:'#0c2166'}}>坚实的对外关系</p>
                        公司拥有资深实验室认可专家二十余人，核心咨询团队主要由国家实验室认可评审专家 、检查机构认可评审专家、商检系统评审专家、实验室资质认定评审专家的资深咨询专家组成。咨询团队为每个客户都提供了专业、高效、优质的咨询指导，并且得到了客户、认可机构和同行业的普遍认可，且与认可机构建立了良好的业务关系，成为实验室认可咨询界的品牌企业。

                    </div>
                    <div className="banner_jianjie_C is_PC" style={{width:700}}>
                        <div className="Scroll-left">
                            <img src={gs} width={'100%'}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="all_fnav is_PC" style={{zIndex:999,position:"relative"}}>
                 <div style={{background:'#fff',height:80}}></div>
                <div className="warp">
                    <div className={'b'}>
                        <img src={logo22} height={108} style={{float:"left"}}/>
                        <div style={{float:"right",lineHeight:'108px',fontSize:'26px',paddingRight:40}}>致力于我国实验室建设、 认证、 扩项、 监督评审、 一站式解决方案</div>
                    </div>
                    <div className="Fanya_footer_nav" style={{color:'#FFF'}}>
                        <ul>
                            <li>
                                
                                <div className="Fanya_footer_nav_TT">CMA资质认定</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>检验检测机构CMA资质认定</dt>
                                        <dt>机动车检验机构CMA资质认定</dt>
                                        <dt>司法鉴定机构CMA资质认定</dt>
                                        <dt>刑事技术机构CMA资质认定</dt>
                                    </dl>
                                </div>
                            </li>
                            <li><div className="Fanya_footer_nav_TT">CNAS认证认可</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>ISO17025检测/校准实验室CNAS认可</dt>
                                        <dt>ISO17020检验机构CNAS认可</dt>
                                        <dt>ISO15189医学实验室CNAS认可</dt>
                                        <dt>DILAC国防实验室认可</dt>
                                    </dl>
                                </div>
                            </li>
                            <li style={{marginRight:15}}>
                                <div className="Fanya_footer_nav_TT">联系我们</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>电话：0574-87636695</dt>
                                        <dt>认证热线：18957441030（胡老师）</dt>
                                        <dt>地址：宁波市高新区检验检测园冬青路258号A幢</dt>
                                        <dt></dt>
                                    </dl>
                                </div>
                            </li>
                            <li>
                                <div className="Fanya_footer_sub" style={{position:"relative"}}>
                                    <img src={weixing} width={160} />
                                    <div style={{background:'red',borderRadius:16,lineHeight:'32px',textAlign:"center",width:160,marginTop:10}}>扫码咨询</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="all_Fanya_copyright">
                <div className="warp">
                    <div className="Fanya_copyright">版权所有 ◎ 浙江安鉴检测认证服务有限公司 <a
                        href="http://beian.miit.gov.cn/" target="_blank">浙ICP备2024103308号-1</a></div>
                </div>
            </div>
        </>
    );
};
export default App;
