import {Layout, Button, Carousel, Tabs, FloatButton,Affix} from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {useEffect, useState} from 'react';
import Script from 'react-load-script';
import $ from 'jquery';
import axios from 'axios';
import {Navigation, Pagination, Scrollbar, A11y, Autoplay} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import {Link} from 'react-router-dom';    // 路由插件
import './ContentIndex.css';
const logoImg = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/logo2.png';
const jieshao = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/xq/培训介绍图片.jpg';
const liucheng = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/xq/cma办理流程图.jpg';
const yemei = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/培训.jpg';
const xia = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/xq/logo至于每一个介绍最下方.png';
const logo22 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/logo22.png';
const weixing = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/weixing.jpg';
// const shouye = "../images/首页.jpg";

const {Header, Footer, Sider, Content} = Layout;
const App = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const [top, setTop] = useState(118);
    const scroll=(y)=>{
        window.scrollTo(0, y);
    }
    function createMarkup() {
        return {
            __html: `
            <div class="nyrcon">
                <div class="nyrt"><h1 class="contact-tit">内审员培训</h1></div>
                <div class="cc-pp">
                    <p><span
                    style="color: rgb(0, 0, 0);">1.了解合格评定和实验室认可的概况及发展；<br />
2.帮助学员正确理解ISO/IEC17025.R/BT214-2017及《检验检测机构资质认定评审准则》等相关要求准则建立实验室质量管理体系，掌握内审实施步骤和内审技巧。<br />
3.学会编制审核计划、检查表和内审报告.<br />
培训对象：检测实验室质量负责任人，技术负责人，内审员和技术人员；</span>
                </p>
                </div>
                <div class="nyrt"><h1 class="contact-tit">评审员培训</h1></div>
                <div class="cc-pp">
                    <p><span
                    style="color: rgb(0, 0, 0);">成为中国合格评定国家认可委员会（以下称“CNAS”）认可评审员（以下称“评审员”）至少需要满足以下条件：<br />
1．根据CNAS对评审资源需求的情况，选择适宜的人员参加CNAS组织的“认可评审员初始培训课程”，并且考试合格；<br />
2．身体健康，体貌端正，无可能影响正常评审工作的身体障碍，<br />
年龄通常60岁（含）以下；<br />
3．大学本科及以上学历，具有相关专业中级或以上技术职称，或具有同等水平的技术职称；<br />
4．全职工作经历<br />
（1）大学本科至少4年（含4年）工作经历；研究生及以上学历至少3年（含3年）工作经历；<br />
（2）认证/审定与核查机构、实验室/检验机构评审员至少有2年与合格评定相关的工作经历，包括认可、认证、检验检测、审核、标准化等；</span>
                </p>
                </div>
                <div class="nyrt"><h1 class="contact-tit">检测方法的培训</h1></div>
                <div class="cc-pp">
                    <p><span
                    style="color: rgb(0, 0, 0);">本实验室提供金属材料领域的相关培训如下：<br />
力学实验：硬度试验、拉伸试验、压缩试验、弯曲试验、冲击试验、疲劳试验<br />

无损探伤：磁粉探伤MT 超声探伤UT 渗透探伤PT 射线探伤RT<br />

腐蚀实验：盐雾试验 晶间腐蚀 硫化氢试验 点腐蚀  电化学试验 应力腐蚀等<br />

成分分析：金属材料成分分析（铝合金、碳钢、钢管等）<br />

金相分析：显微组织、宏观金相、微观金相等。</span>
                </p>
                <p style="text-align: center;"><img width="100%" style="margin: auto;" src="`+jieshao+`" /></p>
                </div>
            </div>`
        }
    }

    return (

        <>
            <FloatButton.BackTop visibilityHeight={0}  style={{zIndex:9999999999999999}}/>
            <div className="fixed is_PC"></div>
            <div className="all_width is_PC">

                <div className="warp">
                    <div className="header">
                         <div className="logo"><a href="#/ContentIndex" style={{float:"left",marginRight:15}}><img src={logoImg} height={108}/></a></div>
                        <div className="new_nav">
                            <ul>
                                <li><a href="#/ContentIndex">首页</a></li>
                                <li><a  href="#/cnas">CNAS认可</a></li>
                                <li><a  href="#/cma">CMA认定</a></li>
                                <li><a  href="#/fanghuo">防火检测</a></li>
                                <li><a  href="#/haishui">海水腐蚀</a></li>
                                <li><a   href="#/about">关于我们</a></li>
                            </ul>
                        </div>

                        <div className="vub_B" style={{position:"fixed",top:"30px"}}>联系电话： 18957441030</div>
                    </div>
                </div>
            </div>
            <div style={{position:'relative'}}>
                {/*<img src={bannerBG}/>*/}
                <img src={yemei} width={'100%'}/>
                <div style={{position:"absolute",top:'15%',left:'50px',fontSize:50,color:'#fff',fontWeight:"bold",width:1000,textAlign:"left"}}>
                    <span>实验室培训/体系运行、金属材料检测技术</span><br />
                    <span style={{fontWeight:100,fontSize:31}}>专业从事实验室认可评审员、内审员、测量不确定度、能力验证、检测方法的培训。</span>
                </div>
            </div>
            <div className={'service-details'} >
                <Affix offsetTop={top}>
                    <div className="l-box" >
                        <div className="s-plate">
                            <div className="d-nav">
                                <ul>
                                    <li onClick={event => scroll(400)}>
												<span>
													<i></i>内审员培训</span>
                                    </li>
                                    <li onClick={event => scroll(570)}>
												<span>
													<i></i>评审员培训</span>
                                    </li>
                                    <li className=""  onClick={event => scroll(786)}>
												<span>
													<i></i>检测方法的培训												</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Affix>

                <div className={'r-box'}>
                    <div dangerouslySetInnerHTML={createMarkup()} />
                </div>
            </div>

            <img src={xia} width={'100%'} style={{zIndex:999,position:"relative",marginTop:80,display:"block"}}/>

             <div className="all_fnav is_PC" style={{zIndex:999,position:"relative"}}>
                 <div style={{background:'#fff',height:80}}></div>
                <div className="warp">
                    <div className={'b'}>
                        <img src={logo22} height={108} style={{float:"left"}}/>
                        <div style={{float:"right",lineHeight:'108px',fontSize:'26px',paddingRight:40}}>致力于我国实验室建设、 认证、 扩项、 监督评审、 一站式解决方案</div>
                    </div>
                    <div className="Fanya_footer_nav" style={{color:'#FFF'}}>
                        <ul>
                            <li>
                                
                                <div className="Fanya_footer_nav_TT">CMA资质认定</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>检验检测机构CMA资质认定</dt>
                                        <dt>机动车检验机构CMA资质认定</dt>
                                        <dt>司法鉴定机构CMA资质认定</dt>
                                        <dt>刑事技术机构CMA资质认定</dt>
                                    </dl>
                                </div>
                            </li>
                            <li><div className="Fanya_footer_nav_TT">CNAS认证认可</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>ISO17025检测/校准实验室CNAS认可</dt>
                                        <dt>ISO17020检验机构CNAS认可</dt>
                                        <dt>ISO15189医学实验室CNAS认可</dt>
                                        <dt>DILAC国防实验室认可</dt>
                                    </dl>
                                </div>
                            </li>
                            <li style={{marginRight:15}}>
                                <div className="Fanya_footer_nav_TT">联系我们</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>电话：0574-87636695</dt>
                                        <dt>认证热线：18957441030（胡老师）</dt>
                                        <dt>地址：宁波市高新区检验检测园冬青路258号A幢</dt>
                                        <dt></dt>
                                    </dl>
                                </div>
                            </li>
                            <li>
                                <div className="Fanya_footer_sub" style={{position:"relative"}}>
                                    <img src={weixing} width={160} />
                                    <div style={{background:'red',borderRadius:16,lineHeight:'32px',textAlign:"center",width:160,marginTop:10}}>扫码咨询</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="all_Fanya_copyright">
                <div className="warp">
                    <div className="Fanya_copyright">版权所有 ◎ 浙江安鉴检测认证服务有限公司 <a
                        href="http://beian.miit.gov.cn/" target="_blank">浙ICP备2024103308号-1</a></div>
                </div>
            </div>
        </>
);
};
export default App;
