import {Affix, FloatButton} from 'antd';
import {useEffect, useState} from 'react';
import '../ContentIndex.css';
const logoImg = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/logo2.png';
const yemei = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/海水腐蚀详情页图.png';
const logo22 = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/logo22.png';
const weixing = 'https://zjcnas.oss-cn-hangzhou.aliyuncs.com/images/weixing.jpg';
const App = () => {
    useEffect(() => {
        // 配置swiper播放 配置项都写这里面
        // console.log(1232112312)
        // const mySwiper = new Swiper('.swiper', {
        //     autoplay: true,
        //     loop: true
        // })
        window.scrollTo(0, 0);

    }, [])
    const [scrollPosition, setScrollPosition] = useState(0);
    const [top, setTop] = useState(118);
    const scroll=(y)=>{
        window.scrollTo(0, y);
    }

    function createMarkup() {
        return {
            __html: `
            <div class="nyrcon">
                <div class="nyrt"><h1 class="contact-tit">海水腐蚀</h1></div>
                <div class="cc-pp">
                    <h2><span style="font-size: 16px; color: rgb(0, 0, 0);"><strong>海水腐蚀试验介绍</strong></span></h2><p>
                    <span style="font-size: 16px; color: rgb(0, 0, 0);"></span></p><p><span style="color: rgb(0, 0, 0);">模拟海水腐蚀试验是指材料(主要是金属构件)在海洋环境中发生的腐蚀。海水腐蚀为电化学腐蚀。海水腐蚀的阳极极化阻滞对大多数金属(铁、钢、铸铁、锌等)都很小，因而腐蚀速度相当大。海水氯离子含量很高，Cl-破坏钝化膜，因此大多数金属在海水中不能建立钝态，在海水中由于钝化的局部破坏，很容易发生空隙和缝隙腐蚀等局部腐蚀。</span></p><p
                    style="text-align: center;"></p><h2><span
                    style="font-size: 16px; color: rgb(0, 0, 0);"><strong>试验方法</strong></span></h2>
                    <p><span
                    style="color: rgb(0, 0, 0);">1、在实验室内用小试样和人工配制的介质，在人为控制的环境下所进行的试验<br />

2、模拟实际介质和环境条件下进行“人工海水浸泡试验”一实验室模拟试验<br />
3、采取一些化学或电化学加速腐蚀措施一实验室加速试验</span>
                </p><p><span style="color: rgb(0, 0, 0);"><strong>检测标准</strong></span></p><p><span
                    style="color: rgb(0, 0, 0);">GBT 5776-2005 金属和合金的腐蚀 金属和合金在表层海水中暴露和评定的导则<br />

1、本标准规定了金属和合金在表层海水中暴露所遵循的条件和方法，以便对不同地点的暴露做有意义的比较。本标准适用的暴露范围从水平面以上潮湿的重要区带(飞溅区和潮汐区)到水平面以下与表面海水组成相近的深度。<br />

2、本标准规定了海水对金属和合金腐蚀的评定方法。<br />

3、由于海水的可变性和复杂性，为减小可变因素的影响，暴露时间应在一年以上。<br />

GB/T 6384-2008 船舶及海洋工程用金属材料在天然环境中的海水腐蚀试验方法<br />

本标准规定了船前及海洋工程用金属材料在天然环境中海水腐蚀试验的条件、装置、比对试样、试样制备、步骤和结果评定方法等。<br />

本标准适用于船舶及海洋工程用金属材料在天然条件下的全浸潮差和飞溅海水腐蚀试验，评定材料的耐蚀性。</span>
                </p>
               
                </div>
            </div>`
        };
    }

    return (

        <>
            <FloatButton.BackTop visibilityHeight={0}  style={{zIndex:9999999999999999}}/>
            <div className="fixed is_PC"></div>
            <div className="all_width is_PC">
                <div className="warp">
                    <div className="header">
                         <div className="logo"><a href="#/ContentIndex" style={{float:"left",marginRight:15}}><img src={logoImg} height={108}/></a></div>
                        <div className="new_nav">
                            <ul>
                                <li><a href="#/ContentIndex">首页</a></li>
                                <li><a  href="#/cnas">CNAS认可</a></li>
                                <li><a  href="#/cma">CMA认定</a></li>
                                <li><a  href="#/fanghuo">防火检测</a></li>
                                <li><a  href="#/haishui">海水腐蚀</a></li>
                                <li><a   href="#/about">关于我们</a></li>
                            </ul>
                        </div>
                        <div className="vub_B" style={{position:"fixed",top:"30px"}}>联系电话： 18957441030</div>
                    </div>
                </div>
            </div>
            <div style={{position:'relative'}}>
                {/*<img src={bannerBG}/>*/}
                <img src={yemei} width={'100%'}/>
                <div style={{position:"absolute",top:'35%',left:'50px',fontSize:50,color:'#fff',fontWeight:"bold",width:1000,textAlign:"center"}}>
                    <span>海水腐蚀(非模拟) </span><br />
                    {/*<span>上门服务 保证一次性通过</span><br />*/}
                    {/*<span style={{fontWeight:100,fontSize:31}}>准则宣贯、文件编制、内部审核、管理评审、质量记录、技术记录。</span>*/}
                </div>
            </div>
            {/*<img src={yemei}/>*/}<div className={'service-details'} >
            <Affix offsetTop={top}>
                <div className="l-box" >
                    <div className="s-plate">
                        <div className="d-nav">
                            <ul>
                                <li onClick={event => scroll(30)}>
												<span>
													<i></i>海水腐蚀试验介绍</span>
                                </li>
                                <li onClick={event => scroll(230)}>
												<span>
													<i></i>试验方法</span>
                                </li>
                                <li onClick={event => scroll(350)}>
												<span>
													<i></i>检测标准</span>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </Affix>
            <div className={'r-box'}>
                <div dangerouslySetInnerHTML={createMarkup()}/>
            </div>
        </div>
            {/*<img src={xia} width={'100%'} style={{zIndex:999,position:"relative",marginTop:80,display:"block"}}/>*/}

             <div className="all_fnav is_PC" style={{zIndex:999,position:"relative"}}>
                 <div style={{background:'#fff',height:80}}></div>
                <div className="warp">
                    <div className={'b'}>
                        <img src={logo22} height={108} style={{float:"left"}}/>
                        <div style={{float:"right",lineHeight:'108px',fontSize:'26px',paddingRight:40}}>致力于我国实验室建设、 认证、 扩项、 监督评审、 一站式解决方案</div>
                    </div>
                    <div className="Fanya_footer_nav" style={{color:'#FFF'}}>
                        <ul>
                            <li>
                                
                                <div className="Fanya_footer_nav_TT">CMA资质认定</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>检验检测机构CMA资质认定</dt>
                                        <dt>机动车检验机构CMA资质认定</dt>
                                        <dt>司法鉴定机构CMA资质认定</dt>
                                        <dt>刑事技术机构CMA资质认定</dt>
                                    </dl>
                                </div>
                            </li>
                            <li><div className="Fanya_footer_nav_TT">CNAS认证认可</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>ISO17025检测/校准实验室CNAS认可</dt>
                                        <dt>ISO17020检验机构CNAS认可</dt>
                                        <dt>ISO15189医学实验室CNAS认可</dt>
                                        <dt>DILAC国防实验室认可</dt>
                                    </dl>
                                </div>
                            </li>
                            <li style={{marginRight:15}}>
                                <div className="Fanya_footer_nav_TT">联系我们</div>
                                <div className="Fanya_footer_sub">
                                    <dl>
                                        <dt>电话：0574-87636695</dt>
                                        <dt>认证热线：18957441030（胡老师）</dt>
                                        <dt>地址：宁波市高新区检验检测园冬青路258号A幢</dt>
                                        <dt></dt>
                                    </dl>
                                </div>
                            </li>
                            <li>
                                <div className="Fanya_footer_sub" style={{position:"relative"}}>
                                    <img src={weixing} width={160} />
                                    <div style={{background:'red',borderRadius:16,lineHeight:'32px',textAlign:"center",width:160,marginTop:10}}>扫码咨询</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="all_Fanya_copyright">
                <div className="warp">
                    <div className="Fanya_copyright">版权所有 ◎ 浙江安鉴检测认证服务有限公司 <a
                        href="http://beian.miit.gov.cn/" target="_blank">浙ICP备2024103308号-1</a></div>
                </div>
            </div>
        </>
    );
};
export default App;
